import Api from './Api.js';

export default {
  getAllPermissions(filters, sort) {
    return Api().get('/v2/permission');
  },
  addPermission(payload) {
    return Api().post('/v2/permission', payload);
  },
  getPermissionByProjectRoleId(payload) {
    return Api().get(`/v2/permission/filter/${payload.project_id}/${payload.role_id}`);
  }
};
